<template>
  <b-form @submit.prevent="destroy">
    <div class="title-actions">
      <h5 class="title">{{$t('myNews')}} - {{$t('delete')}}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="form-group">
      <label for="title">{{$t('title')}}</label>
      <b-form-input id="title" v-model="notice.title" readonly />
    </div>
    <div class="form-group">
      <label for="content">{{$t('content')}}</label>
      <quill-editor ref="myTextEditor" disabled v-model="notice.content" :options="editorOption" readonly></quill-editor>
    </div>
    <div class="form-group">
      <label for="file">{{$t('cover')}}</label>
      <img v-if="imgPath" :src="imgPath" :alt="notice.title" class="img-thumbnail">
    </div>
    <div class="form-group">
      <b-form-checkbox switch v-model="notice.show" name="check-button" disabled>{{$t('show')}}</b-form-checkbox>
    </div>
    <b-button type="submit" variant="danger" :disabled="loading">{{$t('delete')}}</b-button>
    <b-button class="ml-3" @click="$router.go(-1)">{{$t('return')}}</b-button>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      imgPath: '',
      title: '',
      console: this.$route.params.console,
      editorOption: {
        modules: {
          toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link', 'image', 'video']
            ],
           imageDrop: true,
           imageResize: true
         },
         readOnly: true,
         theme: 'snow'
       }
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'notices',
      'notice'
    ])
  },
  mounted() {
    this.changeTitleBar(this.$t('myNews'))
    const noticeId = this.$route.params.noticeId
    if (!noticeId) {
      this.$router.push({ name: 'NoticeUserList', params: { lang: this.lang } })
      return
    } else {
      this.imgPath = this.notice.image
    }
    if (this.notices.length < 1) {
      this.$toastr.error(this.$t('error404'), window.TITLE_ERROR)
      this.$router.push({ name: 'NoticeUserList', params: { lang: this.lang } })
    }
  },
  methods: {
    destroy () {
      const noticeId = this.$route.params.noticeId
      const path = `auth/${this.lang}/${this.console}/notices/user/${noticeId}/delete`
      this.$axios.delete(path).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
        this.$store.dispatch('DELETE_NOTICE', this.notice.id)
        this.$router.push({ name: 'NoticeUserList', params: { lang: this.lang } })
      })
    }
  }
}
</script>
